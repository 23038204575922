import clsx from "clsx";

export function LogoIcon({ className, ...props }: JSX.IntrinsicElements["svg"]) {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className)}
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 22C9.19053 22.0003 8.39479 21.7838 7.69031 21.3716L2.30969 18.2158C1.61154 17.8112 1.02982 17.2221 0.624016 16.5088C0.218209 15.7955 0.00286627 14.9836 0 14.156V7.84448C0.00270751 7.01676 0.217975 6.20464 0.62379 5.49117C1.02961 4.77769 1.61141 4.18846 2.30969 3.78373L7.69031 0.627952C8.395 0.216314 9.19063 0 10 0C10.8094 0 11.605 0.216314 12.3097 0.627952L17.6903 3.78373C18.3689 4.18016 18.9383 4.74915 19.3447 5.4368L12.2828 9.64049C11.5974 10.0558 11.0273 10.6469 10.6274 11.3566C10.2275 12.0663 10.0114 12.8707 10 13.6919V22ZM20 13.071V14.156C19.9973 14.9837 19.782 15.7959 19.3762 16.5093C18.9704 17.2228 18.3886 17.812 17.6903 18.2168L12.3097 21.3726C12.1599 21.4601 12.0056 21.5391 11.8474 21.6092V19.6489C11.854 19.1606 11.9811 18.682 12.2168 18.2587C12.4524 17.8354 12.7888 17.4813 13.1939 17.2301C15.2101 16.0179 17.2406 14.8317 19.2567 13.6196C19.5198 13.4599 19.7685 13.2763 20 13.071V13.071ZM19.9623 7.24488C19.9875 7.4443 20.0001 7.64521 20 7.84633V8.99077C19.9917 9.60109 19.8322 10.199 19.537 10.7276C19.2417 11.2561 18.8204 11.6977 18.3133 12.0101C16.2962 13.2214 14.2657 14.4076 12.2496 15.6198C12.1108 15.7051 11.9766 15.7981 11.8474 15.8982V13.6919C11.8545 13.1984 11.9847 12.7151 12.2253 12.2888C12.4659 11.8624 12.8088 11.5075 13.2208 11.2583L19.9623 7.24488Z"
      />
    </svg>
  );
}
